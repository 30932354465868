@import 'src/styles.module.scss';

$img_size: 16rem;
$tablet_img_size: 10rem;
$mobile_img_size: 10rem;
$mobile_landscape_img_size: 7.2rem;
$backgroundColor: $dark_trans_matte_grey;

.landing {
  height: 100vh;
  @include flex();
  justify-content: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.container {
  @include flex();
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
}

.image {
  height: $img_size;
  width: $img_size;
  border-radius: 100%;
  margin-bottom: 3rem;
  box-shadow: 0.25rem 0.25rem 1rem 0 rgba(0, 0, 0, .5);
}

.name {
  font-size: 4.8rem;
  text-align: center;
  color: white;
  text-shadow: 0.25rem 0.25rem .8rem rgba(0, 0, 0, .5);
}

.tagline {
  color: $hunyadi_yellow;
  font-size: 2rem;
  margin-top: 4rem;
  text-align: center;
  text-shadow: 0.15rem 0.15rem .5rem rgba(0, 0, 0, .5);
  @include flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (min-width: $tablet_screen_width + 1) {
  .tagline {
  //  Add a line between the tagline spans
    & > *:first-child::after {
      content: '|';
      width: 100%;
      height: 0.1rem;
      //background-color: $hunyadi_yellow;
      margin: 0 10px;

    }
  }
}

@media screen and (max-width: $tablet_screen_width) {
  .image {
    height: $tablet_img_size;
    width: $tablet_img_size;
    margin-bottom: 2rem;
  }

  .name {
    font-size: 4rem;
  }

  .tagline {
    font-size: 2rem;
    margin-top: 2rem;
  }
  .container {
    max-width: 70%;
  }
}

@media screen and (max-width: $mobile_screen_width) {
  .image {
    height: $mobile_img_size;
    width: $mobile_img_size;
    margin-bottom: 2rem;
  }

  .tagline {
    font-size: 1.6rem;
    margin-top: 1.6rem;
  }

  .name {
    font-size: 3.2rem;
  }

  .container {
    padding-left: .6rem;
    padding-right: .6rem;
    max-width: 60%;
  }
}

@media screen and (max-height: $mobile_landscape_screen_height) {
  .image {
    height: $mobile_landscape_img_size;
    width: $mobile_landscape_img_size;
    margin-bottom: 1rem;
  }

  .name {
    font-size: 2.4rem;
  }

  .tagline {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}