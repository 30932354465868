@import 'src/styles.module.scss';

$img_size: 20rem;
$tablet_img_size: 18rem;
$mobile_img_size: 15rem;
$mobile_landscape_img_size: 10rem;

.container {
  @include flex;
  justify-content: flex-start;
  margin-top: 7rem;
  flex-direction: row;
  flex-grow: 1;
  z-index: 2;
  //min-height: calc(100vh - $nav_bar_height - $min_history_banner_height);

  color: white;
  @media screen and (max-height: $mobile_landscape_screen_height) {
    min-height: calc(100vh - $mini_nav_bar_height - $min_history_banner_height);
  }
}

.image {
  height: $img_size;
  max-height: 80%;
  border: white solid 0.3rem;
  margin: 1rem 6rem 1rem 0;
}

.text {
  height: 100%;
  flex-grow: .5;
  @include flex;
  align-items: flex-start;
  text-align: left;
  text-shadow: 0.15rem 0.15rem .5rem rgba(0, 0, 0, .3);
}

.name {
  font-size: 2rem;
  font-weight: 450;
  margin-bottom: .8rem;
  color: $hunyadi_yellow;
}

.qualifications {
  margin-bottom: 1.2rem;
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 1.2rem;

  & * {
    margin-bottom: .5rem;
  }
}

.aboutParagraphs {
  @include flex;
  align-items: flex-start;
  margin-top: 1.2rem;
}

.aboutParagraphItem {
  font-size: 1.2rem;

  &:not(:first-child) {
    margin-top: 1.6rem;
  }
}

.qualificationDetails {
  @include flex;
  margin-top: 2.4rem;
  align-items: flex-start;
}

.qualificationDetailItem {
  font-size: 1.3rem;
  font-weight: 500;

  &:not(:first-child) {
    margin-top: 1.2rem;
  }
}

@media screen and (max-width: $tablet_screen_width) {
  .container {
    flex-direction: column;
  }

  .image {
    height: $tablet_img_size;
  }
}

@media screen and (max-width: calc($tablet_landscape_screen_width)) {
  .image {
    height: $mobile_img_size;
    margin: 0 0 1rem 1rem;
  }
  .text {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .name {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-height: $mobile_landscape_screen_height) {
  .name {
    width: 100%;
    text-align: center;
  }
  .container {
    min-height: calc(100vh - $nav_bar_height);
    lex-direction: row;
    padding: 0 2rem;
    width: calc(100% - 4rem) !important;
  }

  .image {
    height: $mobile_landscape_img_size;
    margin: 0 0 1rem 0;
  }

  .text {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}