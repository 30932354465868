@import 'src/styles.module.scss';

$img_size: 2.8rem;

.container {
  height: 28rem;
  @include flex();
  @media screen and (max-height: $mobile_landscape_screen_height) {
    height: 24rem;
  }
}

.sectionHeading {
  @include section-heading;
  padding-top: 5.4rem;
  @media screen and (max-height: $mobile_landscape_screen_height) {
    padding-top: 1.6rem;
  }
}

.emailsContainer {
  margin-top: auto;
  margin-bottom: auto;
  @include flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: $mobile_screen_width) {
    flex-direction: column;
    & > * {
      padding-bottom: .2rem;
    }
  }
}

.emailText {
  font-size: 1.8rem;
  font-weight: 500;
  padding-right: .8rem;
  @media screen and (max-width: $mobile_screen_width) {
    padding-right: 0;
    padding-bottom: .4rem;
  }
}

.emailId {
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 500;
  color: $text_blue;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;

  &:hover {
    color: $hunyadi_yellow;
    text-decoration: underline;
  }
}

.emailDivider {
  font-size: 1.6rem;
  padding: 0 .8rem;
  @media screen and (max-width: $mobile_screen_width) {
    display: none;
  }
}

.socialMediaLinks {
  @include flex;
  flex-direction: row;
  padding-bottom: 5.4rem;
  @media screen and (max-height: $mobile_landscape_screen_height) {
    padding-bottom: 9.2rem;
  }
}

.socialMediaImg {
  height: 4rem !important;
  width: 4rem !important;
  font-size: 2rem;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &:hover {
    color: $hunyadi_yellow !important;
    border-color: $hunyadi_yellow !important;
  }

  @media screen and (max-width: $mobile_screen_width) {
    height: 3.2rem !important;
    width: 3.2rem !important;
    font-size: 1.5rem;
  }
}

.resume {
  height: 3.6rem !important;
  font-size: 1.2rem;
  @include flex;
  flex-direction: row;

  &:hover {
    color: $acrobat_red !important;
    border-color: $acrobat_red !important;
  }

  & :global(.anticon) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: $mobile_screen_width) {
    height: 2.8rem !important;
    font-size: 1rem;
  }
}