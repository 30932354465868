@import 'src/styles.module.scss';

.sectionContainer {
  @include flex;
}

.smallFont {
  & * {
    font-size: .9rem;
  }
}

.content {
  padding: 0 1rem;
  margin: 2rem 0;
  text-align: left;
  overflow: hidden;
  white-space: pre-wrap;
}

.subSectionTitle {
  @include item-title;
  color: $hunyadi_yellow;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 1.6rem;
  font-weight: 500;
}

.publication {
  @include flex;
  flex-direction: row;

  &:not(:first-child) {
    margin-top: 2rem;
  }
}

.publicationImageContainer {
  width: 25%;
  margin-right: 2rem;
  @include flex;
}

.publicationImage {
  width: 100%;
  border: 0.15rem solid $hunyadi_yellow_trans;
  max-height: 11rem;
}

.publicationDetails {
  width: 75%;
}

.publicationTitle {
  font-weight: 500;
  font-size: 1.4rem;
}

.publicationAuthors {
  margin-top: 1rem;
  font-size: 1.1rem;
}

.publicationVenue {
  margin-top: 1rem;
  white-space: break-spaces;
  font-style: oblique;
}

.publicationAward {
  margin-top: .5rem;
  white-space: break-spaces;
  font-weight: bold;
  font-size: .9rem;
}

.publicationLinksContainer {
  @include flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: flex-start;

  color: $text_blue;

  & > * {
    margin-right: .3rem;
  }
}

.publicationLink {
  color: $text_blue;
}

@media screen and (max-width: $tablet_screen_width) {
  .publication {
    flex-direction: column;
  }

  .publicationImageContainer {
    width: 70%;
    margin-bottom: 1rem;
  }

  .publicationImage {
    width: unset;
    max-height: 12rem;
  }

  .publicationDetails {
    @include full-width;
  }
}

@media screen and (max-width: $mobile_screen_width) {
  .content {
    width: calc(100vw - 4rem);
  }
}
