@import 'src/styles.module';

$img_max_height: 7.2rem;
$img_max_width: 10rem;
$tablet_img_max_height: 6rem;
$tablet_img_max_width: 9rem;

.container {
  min-height: $min_history_banner_height;
  //backdrop-filter: blur(0px) !important;
  //background-color: $matte_grey !important;
  //box-shadow: 0 -0.05rem .8rem 0.2rem rgba(0, 0, 0, 0.2);
  box-shadow: inset rgba(0, 0, 0, 0.3) 0 0 1rem 0.8rem;
  background: $light_trans_matte_grey;
  @include flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 6rem;
  margin: 0 3vw;
  padding: .5rem 2rem;
  min-width: calc($img_max_width + 2rem); ;
}

.image {
  max-height: $img_max_height;
  max-width: $img_max_width;
  padding: 1rem 1rem;
  border-radius: 1rem;
}

@media screen and (max-width: $tablet_screen_width) {
  .image {
    max-height: $tablet_img_max_height;
    max-width: $tablet_img_max_width;
    padding-top: 2rem;
  }

  .container {
    padding: 1rem 2rem;
  }
}

@media screen and (max-height: $mobile_landscape_screen_height) {
  .container {
    padding: 0 2rem;
    width: calc(100% - 4rem) !important;
  }
}