@import "src/styles.module";

$menu_icon_size: 1.6rem;
$mini_menu_icon_size: 1.2rem;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc($nav_bar_height - 4rem);
  top: 2rem;
  left: 20%;
  right: 20%;
  width: 60%;
  background: $matte_grey;
  color: black;
  position: fixed;
  border-radius: 2rem;
  z-index: 4;

  transition: all .2s ease;

  box-shadow: 0.2rem 0.2rem 0.5rem 0.1rem rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translate3d(0, -0.05rem, 0);
    box-shadow: 0.2rem 0.2rem 0.7rem 0.2rem rgba(0, 0, 0, 0.2);
  }
}

.name {
  white-space: nowrap;
  user-select: none;
  margin-left: 2.4rem;
  font-size: 2rem;
  cursor: pointer;
  transition: all .2s ease;
  color: white;
  -webkit-transition: all .2s ease;
  text-align: center;
  vertical-align: center;

  &:hover {
    color: $hunyadi_yellow !important;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  margin-right: 2rem;
  margin-left: 2rem;
  height: 100%;
}

.navBarItem {
  color: azure;
  font-size: 1rem;
  padding-left: .6rem;
  padding-right: .6rem;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    color: $hunyadi_yellow !important;
  }
}

.menu {
  margin-right: 1.6rem;
  cursor: pointer;
  z-index: inherit;
}

.menuIcon {
  height: $menu_icon_size;
  width: $menu_icon_size;
  stroke: white;

  &:hover {
    stroke: $hunyadi_yellow !important;
  }
}


:global(.ant-dropdown-menu-item) {
  transition: all .2s ease;
  -webkit-transition: all .2s ease;

  &:hover {
    color: $hunyadi_yellow !important;
  }
}

.menuItem {
  font-size: 1rem;
}

@media screen and (max-width: $small_desktop_landscape_screen_width) {
  .container {
    left: 18%;
    right: 18%;
    width: 64%;
  }

  .name {
    margin-left: 1.2rem;
  }

  .actions {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .navBarItem {
    font-size: .9rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

@media screen and (max-width: $tablet_landscape_screen_width) {
  .container {
    left: 15%;
    right: 15%;
    width: 70%;
  }

  .name {
    font-size: 1.4rem;
    margin-left: 1.2rem;
  }

  .actions {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .navBarItem {
    padding-left: .4rem;
    padding-right: .4rem;
    font-size: .9rem;
  }
}

@media screen and (max-width: $tablet_screen_width) {
  .name {
    font-size: 1.2rem;
    margin-left: 1.2rem;
  }
  .navBarItem {
    padding-left: .2rem;
    padding-right: .2rem;
    font-size: .7rem;
  }
}

@media screen and (max-width: $mobile_landscape_width) {
  .name {
    font-size: 1rem;
    margin-left: 1.2rem;
  }
  .navBarItem {
    padding-left: .2rem;
    padding-right: .2rem;
    font-size: .7rem;
  }
}

@media screen and (max-width: $mobile_screen_width) {
  .container {
    left: 10%;
    right: 10%;
    width: 80%;
  }
  .name {
    font-size: 1.6rem;
    margin-left: 1.2rem;
  }
}

@media screen and (max-width: $mini_mobile_screen_width) {
  .container {
    width: 15rem !important;
  }

  .name {
    font-size: 1.3rem;
    margin-left: 1rem;
  }
}

@media screen and (max-height: $mobile_landscape_screen_height) {
  .name {
    font-size: 1.6rem;
    margin-left: 1rem;
  }

  .menuIcon {
    height: $mini_menu_icon_size;
    width: $mini_menu_icon_size;
  }

  .container {
    height: $mini_nav_bar_height;
  }
}