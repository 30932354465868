@import 'src/styles.module.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.2;
  }

  30% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}


.container {
  @include flex;
  @include full-height;

  //&.visibleContainer {
  //  padding: .5rem .5rem .5rem 0;
  //}
}

.detailsContainer {
  @include flex;
  @include full-height;
  flex-direction: row;
  padding: 1rem 0;
}

.contentContainer {
  @include full-height;
  width: 100%;
  flex-grow: 1;
  @include flex;
  padding-left: 0.6rem;
}

.skillDetailHeading {
  margin: 1rem 0;
  @include flex;
  justify-content: center;
  animation: fadeIn 250ms;
  width: 100%;
}

.sectionHeading {
  color: $hunyadi_yellow;
  @include item-title;
  font-size: 1.8rem;
  text-align: center;
  margin-right: 3.2rem;
}

.closeDetailsButton {
  width: 2rem !important;
  height: 5rem !important;
  margin-right: .6rem !important;
  font-size: 1.4rem !important;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: none;

  &:hover {
    color: $hunyadi_yellow !important;
  }
}

.content {
  animation: fadeIn 250ms;
  padding-bottom: 0.8rem;
}

