$nav_bar_height: 8rem;
$mini_nav_bar_height: 2.8rem;

$item_title_font_size: 1.4rem;
$item_mini_title_font_size: 1.2rem;
$item_desc_font_size: 1rem;

$mini_mobile_screen_width: 360px;
$mobile_screen_width: 720px;
$mobile_landscape_width: 750px;
$tablet_screen_width: 900px;
$tablet_landscape_screen_width: 1150px;
$small_desktop_landscape_screen_width: 1400px;

$mobile_landscape_screen_height: 650px;

$min_history_banner_height: 9.6rem;

@mixin flex {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@mixin full-width {
  width: 100%;
}

@mixin full-height {
  height: 100%;
}

@mixin section-heading {
  font-size: 2.4rem;
  font-weight: 500;
}

@mixin item-title {
  font-size: $item_title_font_size;
  font-weight: 400;

  @media screen and (max-width: $tablet_screen_width) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: $mobile_screen_width) {
    font-size: 1.2rem;
  }
}

@mixin resize-transition {
  transition: width .5s ease-in-out, height .5s ease-in-out;
}

@mixin transition {
  transition: .3s ease-in-out;
}

@mixin item-mini-title {
  @include item-title;
  font-size: $item_mini_title_font_size;

  @media screen and (max-width: $mobile_screen_width) {
    font-size: 1.2rem;
  }
}

@mixin item-description {
  font-size: $item_desc_font_size;

  @media screen and (max-width: $tablet_screen_width) {
    font-size: 1rem;
  }
}

@mixin main-background {
  color: #000;
  background: linear-gradient(180deg, $backgroundColor 70%, transparent 0) 0 2rem, linear-gradient(90deg, currentColor 1%, transparent 1%) 0.5rem 0, linear-gradient(90deg, $backgroundColor 70%, transparent 0) 2rem 0, linear-gradient(180deg, currentColor 1%, transparent 1%) 0.5rem 0.5rem;
  background-size: calc(2 * 2.5rem) calc(2 * 2.5rem);
}

$honolulu_blue: #2176ae;
$text_blue: #48A6E9;
$argentinian_blue: #57b8ff;
$ink_blue: #16264C;
$tigers_eye_brown: #b66d0d;
$text_yellow: #fcc923;
$hunyadi_yellow: #fbb13c;
$hunyadi_yellow_trans: rgba(251, 177, 60, 0.8);
$tomato_red: #fe6847;
$dutch_white: #e8dab2;
$payne_grey: #4F6D7A;
$columbia_blue: #C0D6DF;
$platinum_blue: #EAEAEA;
$text_grey: #D8D9DD;
$aluminium: #a7adba;
$burnt_red: #DD6E42;
$chrome_yellow: #fcbc1d;
$dark_chrome_yellow: #fca31d;
$backgroundGray: #363739;
$light_matte_grey: #3b3e46;
$mid_matte_grey: #3c3d3f;
$matte_grey: #202124;
$light_trans_matte_grey: #20212488;
$dark_trans_matte_grey: #202124DF;
$mint_green: #DAFFEF;
$verdegris: #64B6AC;
$greenish-blue: #266f72;
$mint_blue: #cafffb;
$acrobat_red: #b30b00;

