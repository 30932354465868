@import 'src/styles.module.scss';

$item_list_width: 30rem;

.container {
  @include flex;
  @include resize-transition;
  min-height: calc(90vh - $nav_bar_height);

  align-items: stretch;
  flex-direction: row;

  @media screen and (max-height: $mobile_landscape_screen_height) {
    min-height: calc(100vh - $mini_nav_bar_height);
    padding: 0 2rem;
    width: calc(100% - 4rem) !important;
  }
}

.itemsContainer {
  @include flex;
  @include resize-transition;

  flex-grow: 1;
  position: relative;
  width: 100%;
}

.sectionHeading {
  color: white;
  margin-bottom: 2rem;
  @include flex;
  @include resize-transition;
}

.itemsGridContainer {
  @include resize-transition;
  @include flex;

  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 10%;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: inset rgba(0, 0, 0, 0.3) 0 0 1rem 0.8rem;
  background: $light_trans_matte_grey;
  margin-bottom: 1rem;
  border-radius: 0.8rem;
  & > * {
    margin: 1rem;
  }
}

.itemDetails {
  @include resize-transition;
  width: 0;
}

.itemDetailsContent {
  @include full-width;
  margin-bottom: 1rem;
  flex-grow: 1;
  //box-shadow: inset $dark_trans_matte_grey 0 0 1rem 0.8rem;
  //background: $light_trans_matte_grey;
  //border-radius: .4rem;
}


.detailsVisible {
  &.container {
    height: calc(100vh - $nav_bar_height);
  }

  & .itemsContainer {
    width: $item_list_width;
  }

  & .itemDetails {
    width: calc(100% - $item_list_width);
  }

  & .itemsGridContainer {
    width: calc(100%);
    padding: 1rem 0 1rem 0;
    overflow: scroll;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: .4rem;
    margin-bottom: 1rem;
  }
}